var Imagecropped;

module.exports = Imagecropped = (function() {
  function Imagecropped(selector) {
    this.selector = selector;
    this.$elements = jQuery(this.selector);
    if (Drupal.settings.lt_ie9 != null) {
      return;
    }
    if (!this.$elements.length) {
      return;
    }
    this.$elements.each(function(index, value) {
      this.$element = jQuery(value);
      this.$img = this.$element.find('img');
      this.src = this.$img.attr('src');
      this.$element.css({
        "background-image": 'url(' + this.src + ')'
      });
      return this.$img.css({
        visibility: "hidden"
      });
    });
  }

  return Imagecropped;

})();
