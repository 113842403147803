var Map;

module.exports = Map = (function() {
  function Map() {
    var elementPrimary, elementSecondary, elementTertiary, mapPrimary, mapSecondary, mapTertiary, settings;
    mapPrimary = 'map--map-1';
    mapSecondary = 'map--map-2';
    mapTertiary = 'map--map-3';
    settings = {
      primary: {
        lat: 49.607300,
        long: 7.510230,
        markers: {
          marker_01: {
            lat: 49.607300,
            long: 7.510230
          }
        }
      },
      secondary: {
        lat: 49.656974,
        long: 7.591875,
        markers: {
          marker_01: {
            lat: 49.656974,
            long: 7.591875
          }
        }
      },
      tertiary: {
        lat: 49.400170,
        long: 7.575160,
        markers: {
          marker_01: {
            lat: 49.400170,
            long: 7.575160
          }
        }
      }
    };
    elementPrimary = document.getElementById(mapPrimary);
    if (elementPrimary) {
      this.init(elementPrimary, settings.primary);
    }
    elementSecondary = document.getElementById(mapSecondary);
    if (elementSecondary) {
      this.init(elementSecondary, settings.secondary);
    }
    elementTertiary = document.getElementById(mapTertiary);
    if (elementTertiary) {
      this.init(elementTertiary, settings.tertiary);
    }
  }

  Map.prototype.init = function(_element, _settings) {
    var _defaults, _map, _options, _styledMap, styles;
    _defaults = {
      disableDefaultUI: true,
      zoom: 17,
      disableDoubleClickZoom: true,
      draggable: false,
      scrollwheel: false,
      panControl: false
    };
    _options = jQuery.extend({}, _defaults, _settings);
    if (_settings.lat && _settings.long) {
      _options.center = new google.maps.LatLng(_options.lat, _options.long);
    }
    styles = this.get_styles();
    _styledMap = new google.maps.StyledMapType(styles, {
      name: "Styled Map"
    });
    _options.mapTypeControlOptions = {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
    };
    _map = new google.maps.Map(_element, _options);
    _map.mapTypes.set('map_style', _styledMap);
    _map.setMapTypeId('map_style');
    if (_settings.markers) {
      this.set_markers(_settings.markers, _map);
    }
    return jQuery(window).resize((function(_this) {
      return function(event) {
        return _map.setCenter(_options.center);
      };
    })(this));
  };

  Map.prototype.set_markers = function(_markers, _map) {
    var k, results, v;
    results = [];
    for (k in _markers) {
      v = _markers[k];
      results.push(new google.maps.Marker({
        position: new google.maps.LatLng(v.lat, v.long),
        map: _map,
        icon: '/sites/all/themes/yh/images/pin--map.png'
      }));
    }
    return results;
  };

  Map.prototype.get_styles = function() {
    var style;
    return style = [
      {
        "featureType": "administrative",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }, {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      }, {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
          {
            "hue": "#00d9d9"
          }, {
            "saturation": -95
          }, {
            "lightness": -15
          }
        ]
      }, {
        "featureType": "landscape.natural",
        "stylers": [
          {
            "color": "#a3afaf"
          }
        ]
      }, {
        "featureType": "landscape.man_made",
        "stylers": [
          {
            "color": "#b2bebe"
          }
        ]
      }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#bcc9c9"
          }
        ]
      }, {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#a8b4b4"
          }
        ]
      }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#808989"
          }
        ]
      }, {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#c0cece"
          }
        ]
      }, {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -100
          }
        ]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#7d8585"
          }
        ]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#6d7575"
          }
        ]
      }, {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#313434"
          }
        ]
      }, {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#a1adad"
          }
        ]
      }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#9aa5a5"
          }
        ]
      }, {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#808989"
          }
        ]
      }, {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#c0cece"
          }
        ]
      }
    ];
  };

  return Map;

})();
