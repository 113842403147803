var Offcanvas;

module.exports = Offcanvas = (function() {
  function Offcanvas() {
    this.body = jQuery('body');
    this.trigger = jQuery('#offcanvas--trigger');
    this.overlay = jQuery('#offcanvas--overlay');
    this.trigger.click((function(_this) {
      return function(event) {
        event.preventDefault();
        return _this.body.toggleClass('offcanvas--active');
      };
    })(this));
    this.overlay.click((function(_this) {
      return function(event) {
        return _this.body.removeClass('offcanvas--active');
      };
    })(this));
  }

  return Offcanvas;

})();
