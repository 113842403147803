var Breakpoints, Imagecropped, Map, Modernizr, Offcanvas, Tabs;

require('../../../../../bower_components/jquery.uniform/jquery.uniform.js');

Modernizr = require('./modernizr');

Breakpoints = require('../../../../../bower_components/js-breakpoints/breakpoints.js');

Imagecropped = require('./components/imagecropped');

Tabs = require('./components/tabs');

Map = require('./components/map');

Offcanvas = require("./components/offcanvas");

Drupal.behaviors.dd_theme_yh = {
  attach: function() {
    var dateservice_date;
    jQuery('.form-radio, .form-select').uniform();
    dateservice_date = jQuery('.webform-client-form-51 #edit-submitted-date');
    if (dateservice_date.length) {
      dateservice_date.datepicker({
        minDate: 0,
        maxDate: 100
      });
    }
    new Imagecropped('.image--cropped');
    new Tabs('.tabs--container');
    new Map;
    return new Offcanvas;
  }
};

Drupal.behaviors.dd_theme_stork = {
  attach: function() {
    var $stork, _destination;
    $stork = jQuery('.base--stork');
    if (!$stork.length) {
      return;
    }
    if (Modernizr.csstransforms) {
      _destination = 1;
      if (_destination > 4) {
        _destination = 1;
      }
      setTimeout(function() {
        return $stork.addClass('destination-' + _destination);
      }, 1000);
      return setInterval(function() {
        $stork.removeClass('destination-1');
        $stork.removeClass('destination-2');
        $stork.removeClass('destination-3');
        $stork.removeClass('destination-4');
        $stork.removeClass('is-flying-backwards');
        $stork.addClass('destination-' + _destination);
        if (_destination % 2 === 0) {
          $stork.addClass('is-flying-backwards');
        }
        _destination += 1;
        if (_destination > 4) {
          return _destination = 1;
        }
      }, 8000);
    } else {
      return setTimeout(function() {
        return $stork.addClass('destination-1');
      }, 1000);
    }
  }
};
