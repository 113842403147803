var Breakpoints, Tabs;

Breakpoints = require('../../../../../../bower_components/js-breakpoints/breakpoints.js');

require('../../../../../../bower_components/jquery-accessible-tabs/js/jquery.tabs.js');

module.exports = Tabs = (function() {
  function Tabs(selector) {
    this.selector = selector;
    this.$elements = jQuery(this.selector);
    if (!this.$elements.length) {
      return;
    }
    this.$elements.each(function(index, element) {
      this.$element = jQuery(element);
      return this.$element.accessibleTabs({
        tabhead: '.tabs--headline',
        tabbody: '.tabs--content',
        fx: 'fadeIn'
      });
    });
  }

  return Tabs;

})();
